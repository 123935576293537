import React from 'react';
import { useNavigate } from 'react-router-dom';
import Appbar from './components/Appbar';
import Sidebar from './components/Sidebar';

const Dashboard = () => {
    const navigate =useNavigate()
    return (
        <div>
            <Appbar></Appbar>
            <h1 onClick={() => navigate('/blogs')}>All Blogs</h1>

            <Sidebar />
        </div>
    );
};

export default Dashboard;