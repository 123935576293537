import { Box } from '@mui/material';
import React from 'react';
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import AddBlogForm from '../../components/Blogs/AddBlogForm';
import PageHeading from '../../components/PageHeading';

function AddBlogPage(props) {
    const navigate = useNavigate();

    return (
        <Box sx={{padding:'30px 15px'}}>
            <div className='flex flex-row items-center space-x-2'>
                <div className="flex flex-row justify-start items-center"> <p className='text-green-700 underline  rounded-full text-base py-2 text-center font-semibold cursor-pointer' onClick={() => navigate("/blogs")}><MdArrowBackIos /></p></div>
                {/* page heading  */}
                <PageHeading text="Add Blog" />
            </div>
            
            {/* form  */}
            <Box sx={{
                background:'#fff',
                borderRadius:'10px',
                padding:'25px',
                boxSizing:'borderBox',
                margin:'30px 0px'
            }}>
                <AddBlogForm />
            </Box>
        </Box>
    );
}

export default AddBlogPage;