import { FormControl, Grid, Select, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import React, { useState } from 'react';
import Datetime from 'react-datetime';
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { BsFillCameraFill } from 'react-icons/bs';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';

export default function UpdateBlogForm({ id }) {
    const [blogData, setBlogData] = React.useState({})
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm();

    //description
    const [updateDescription, setUpdateDescription] = useState('');
    const [previousDescription, setPreviousDescription] = useState('');
    //tag
    const [upTag, setUpTag] = useState('');
    const [prevTag, setPrevTag] = useState('');

    
    //update Date
    const [updatedDate, setUpdatedDate] = useState('');

    //prev time and date
    const [previousDate, setPreviousDate] = useState('');
    const [previousTime, setPreviousTime] = useState('');

    const puslishedNewDate = updatedDate?._d?.toLocaleDateString('en-us', { month: 'short', year: 'numeric', day: 'numeric' })
    const puslishedNewTime = updatedDate?._d?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });


    const [files, setFiles] = React.useState([])
    React.useEffect(() => {
        axios.get(`https://mw-server.vercel.app/api/blogs/${id}`)
            .then(res => {
                setBlogData(res?.data);
                setPreviousDescription(res?.data?.description);
                setPreviousDate(res?.data?.published_date)
                setPreviousTime(res?.data?.published_time)
                setPrevTag(res?.data?.tag);
                setUpTag(res?.data?.tag);
            })

    }, [id])


    //img uploading 
    //image drag and drop
    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })
    const image1 = files?.map((file) => (
        <div key={file.name}>
            <div>
                <img src={file.preview} className='w-full object-contain' alt="preview" />
            </div>
        </div>
    ))


    const formdata = new FormData();
    formdata.append('image', files?.[0])



    const onSubmit = data => {
        // data.description = updateDescription;
        // title 
        if (data?.title === "") {
            data.title = blogData?.title;
        }
        // description 
        if (updateDescription) {
            data.description = updateDescription;


        } else {
            data.description = blogData?.description;
        }

        // date & Time 
        if(updatedDate){
            data.published_date = puslishedNewDate;
            data.published_time = puslishedNewTime;

        }else{
            data.published_date = previousDate;
            data.published_date = previousDate;

        }
        



        // category 
        if (data?.category === "") {
            data.category = blogData?.category;
        }

        // tag 
           if(upTag){
            data.tag = upTag;

        }else{
            data.tag = prevTag;

        }
       


        if (files?.length > 0) {
            axios.post('https://api.imgbb.com/1/upload?&key=830682afc3e5e6fbcf788ea07e7aa7e9', formdata)
                .then((response) => {
                    if (response.status === 200) {
                        data.image = `${response.data.data.image.url}`;
                        axios.put(`https://mw-server.vercel.app/api/blogs/${id}`, { ...blogData, ...data })
                            .then(res => {
                                swal("Let's Go🚀", "Updated Done!", "success");
                                navigate('/blogs')
                            }).catch((err) => {
                                swal("Something went wrong!", `${err.message}`, "error")
                            })
                    }


                }).catch((error) => {
                    alert('try agian')
                })

        } else {
            axios.put(`https://mw-server.vercel.app/api/blogs/${id}`, { ...blogData, ...data })
                .then(res => {
                    swal("Let's Go🚀", "Updated Done!", "success");
                    navigate('/blogs')
                }).catch((err) => {
                    swal("Something went wrong!", `${err.message}`, "error")
                })
        }




    };
    //tag lists
    const tag_list = [
        { id: 1, tag: "Politics" },
        { id: 2, tag: "Education" },
        { id: 3, tag: "International" },
        { id: 4, tag: "National" },
        { id: 5, tag: "Books" },
        { id: 6, tag: "Entertainment" },
        { id: 7, tag: "Agriculture" },
        { id: 8, tag: "Economy" },
        { id: 9, tag: "Food" },
        { id: 10, tag: "Travel" },
        { id: 12, tag: "Opinion" },
        { id: 13, tag: "Science & Tech" },
        { id: 14, tag: "Climate & Environment" }
    ];

    // tag handle change 
    const handleChangeTag = (e) => {
        setUpTag(e.target.value);
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>


            {/* blog title  */}
            <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }} sx={{ marginBottom: 3 }}>
                <Grid item xs={4} sm={8} md={12} lg={12}>
                    <FormControl variant="filled" sx={{ width: '100%' }}>
                        <Typography sx={{ marginBottom: 1 }}>Update Title</Typography>
                        <input className="border border-gray-500 py-4 px-2 rounded-lg" placeholder="Update Title" defaultValue={blogData?.title} {...register("title")} />
                    </FormControl>
                </Grid>

            </Grid>

            {/* blog description   */}
            <div >
                <Typography sx={{ marginBottom: 1 }}>Update Description</Typography>
                <div>
                    <Editor
                        apiKey='10rdb2dzu93qtyg352b971zjwr903zmsfx7bk92q3z56cu5g'
                        init={{
                            plugins: '  anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount ',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                                { value: 'First.Name', title: 'First Name' },
                                { value: 'Email', title: 'Email' },
                            ],
                            selector: 'textarea',  // change this value according to your HTML
                            autosave_interval: '20s',
                            max_height: 800
                        }} 
                        
                        value={previousDescription}
                        onInit={(evt, editor) => {
                            setUpdateDescription(editor.getContent({ format: 'html' }));
                        }}
                        onEditorChange={(newValue, editor) => {
                            setPreviousDescription(newValue);
                            setUpdateDescription(editor.getContent({ format: 'html' }));
                        }}


                    />
                </div>
           

            <div className='mt-8 flex flex-row items-center justify-between'>
                {/* img */}
                <div >
                    <Typography sx={{ marginBottom: 1 }}>Choose an image</Typography>
                    {/* <ImageUpload /> */}
                    {/* image  */}
                    <div className='mb-4 flex flex-col items-center space-y-4 w-48' {...getRootProps()}>
                        <div className='flex flex-col space-y-3 items-center justify-between'>
                            {/* img button  */}
                            <div>
                                <div className="flex border border-gray-200 px-4 rounded-lg space-x-3 py-4 items-center h-full relative">
                                    <BsFillCameraFill className="text-3xl text-gray-600" />
                                    <span className="text-gray-600 font-semibold">Change Photo</span>
                                </div>
                            </div>


                            {/* preview image  */}
                            {files?.length < 1 ? (
                                <>
                                    <div>
                                        <img src={blogData?.image} className='w-full object-contain' alt="preview" /></div>

                                </>
                            ) : (
                                <>
                                    <div className=''>{image1}</div>

                                </>
                            )}


                        </div>

                        <input className="input-primary" {...getInputProps()} placeholder="Upload Your Files" />

                    </div>
                    </div>
                    {/* time and date picker  */}
                    <div >
                        <Typography sx={{ marginBottom: 1 }}>Set Date & Time ({previousDate + " " + previousTime})</Typography>
                        <Datetime
                            className='border border-gray-400 py-4 px-2 rounded-md'
                            initialValue={previousDate + " " + previousTime}
                            onChange={(newValue) => setUpdatedDate(newValue)}

                        />
                    </div>
                    {/* category  */}
                    <div>
                        <FormControl variant="filled" sx={{ width: '100%' }}>
                            <Typography sx={{ marginBottom: 1 }}>Category</Typography>
                            <input className="border border-gray-500 py-4 px-2 rounded-lg" placeholder="Update Category" defaultValue={blogData?.category} {...register("category")} />
                        </FormControl>
                    </div>
                    {/* tag  */}
                    <div>
                        <FormControl variant="outlined" sx={{ width: '100%' }}>
                            <Typography sx={{ marginBottom: 1 }}>Tag</Typography>
                            <Select
                                native
                                value={upTag}
                                onChange={handleChangeTag}
                                label="tag"
                            >
                                {tag_list?.map(i => (
                                    <>
                                        <option value={i?.tag}>{i?.tag}</option>
                                    </>
                                ))}
                            </Select>
                        </FormControl>

                    </div>
                   

                </div>
            

            </div>

            {/* buttons  */}
            <div className="flex flex-row justify-end items-center mt-12">
                <button type="submit" className='text-white bg-green-700 w-24 rounded-full text-base py-2 text-center font-semibold cursor-pointer hover:bg-green-800 transition duration-200'>Update</button>
            </div>
        </form>
    );
}
