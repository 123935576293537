import { FormControl, Grid, Select, TextField, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import React, { useState } from 'react';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";
import { BsFillCameraFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import useAuth from '../../hooks/useAuth';

function AddBlogForm() {
    const navigate = useNavigate()
    const { register, handleSubmit } = useForm();
    const [files, setFiles] = React.useState([])
    const { user } = useAuth();
    const [date, setDate] = React.useState();
    const [newTag, setNewTag] = React.useState('');

    const puslishedDate = date?._d?.toLocaleDateString('en-us', { month: 'short', year: 'numeric', day: 'numeric' })
    const puslishedTime = date?._d?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    //img uploading 
    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            )
        },
    })
    const image1 = files?.map((file) => (
        <div key={file.name}>
            <div>
                <img src={file.preview} className='w-full object-contain' alt="preview" />
            </div>
        </div>
    ))

    const formdata = new FormData();
    formdata.append('image', files?.[0])

    const onSubmit = data => {
        data.description = description;
        data.tag = newTag;
        data.author = { name: `${user?.displayName}`, image: `${user?.photoURL}` };
        data.published_date = puslishedDate;
        data.published_time = puslishedTime;
        if (files?.length > 0) {
            axios.post('https://api.imgbb.com/1/upload?&key=830682afc3e5e6fbcf788ea07e7aa7e9', formdata)
                .then((response) => {
                    if (response.status === 200) {
                        data.image = `${response.data.data.image.url}`;
                        axios.post(`https://mw-server.vercel.app/api/blogs`, data)
                            .then(res => {
                                swal("Let's Go🚀", "Published!", "success");
                                navigate('/blogs');
                                // window.location.reload()
                            }).catch((err) => {
                                swal("Something went wrong!", `${err.message}`, "error")
                            })
                    }


                }).catch((error) => {
                    alert('try agian')
                })

        }

    }

    //blog editor 

    const textToHtml = (text) => {
        const elem = document.createElement('div');
        return text.split(/\n\n+/).map((paragraph) => {
            return '<p>' + paragraph.split(/\n+/).map((line) => {
                elem.textContent = line;
                return elem.innerHTML;
            }).join('<br/>') + '</p>';
        }).join('');
    };
    const initialText = 'Write your blog here!';
    const [description, setDescription] = useState(initialText);


    //tag lists
    const tag_list = [
        { id: 1, tag: "Politics" },
        { id: 2, tag: "Education" },
        { id: 3, tag: "International" },
        { id: 4, tag: "National" },
        { id: 5, tag: "Books" },
        { id: 6, tag: "Entertainment" },
        { id: 7, tag: "Agriculture" },
        { id: 8, tag: "Economy" },
        { id: 9, tag: "Food" },
        { id: 10, tag: "Travel" },
        { id: 12, tag: "Opinion" },
        { id: 13, tag: "Science & Tech" },
        { id: 14, tag: "Climate & Environment" }
    ];

    // tag handle change 
    const handleChangeTag = (e) => {
        setNewTag(e.target.value);
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <div>
                {/* blog title  */}
                <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }} sx={{ marginBottom: 3 }}>
                    <Grid item xs={4} sm={8} md={12} lg={12}>
                        <FormControl variant="filled" sx={{ width: '100%' }}>
                            <Typography sx={{ marginBottom: 1 }}>Title</Typography>
                            <TextField id="outlined-basic" variant="outlined" placeholder="set a title" color="success" {...register("title")} />
                        </FormControl>
                    </Grid>

                </Grid>

                {/* blog description   */}
                <div >
                    <Typography sx={{ marginBottom: 1 }}>Description</Typography>
                    <Editor
                        apiKey='10rdb2dzu93qtyg352b971zjwr903zmsfx7bk92q3z56cu5g'
                        init={{
                            plugins: '  anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount ',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat | aidialog  aishortcuts',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                                { value: 'First.Name', title: 'First Name' },
                                { value: 'Email', title: 'Email' },
                            ],

                            //                             ai_request: (request, respondWith) => {
                            //                                 //ai implementation
                            //                                 const openAiOptions = {
                            //                                     method: 'POST',
                            //                                     headers: {
                            //                                         'Content-Type': 'application/json',
                            //                                         'Authorization': `Bearer ${10rdb2dzu93qtyg352b971zjwr903zmsfx7bk92q3z56cu5g
                            //                                     }`
                            //         },
                            //         body: JSON.stringify({
                            //             model: 'gpt-3.5-turbo',
                            //             temperature: 0.7,
                            //             max_tokens: 800,
                            //             messages: [{
                            //                 role: 'user',
                            //                 content: `${ request.prompt
                            //                         }`
                            //             }],
                            //         })
                            // }

                            //                                 respondWith.string((signal) => window.fetch('https://api.openai.com/v1/chat/completions', { signal, ...openAiOptions })
                            //                                     .then(async (response) => {
                            //                                         if (response) {
                            //                                             const data = await response.json();
                            //                                             if (data.error) {
                            //                                                 throw new Error(`${ data.error.type}: ${data.error.message} `);
                            //                                             } else if (response.ok) {
                            //                                                 // Extract the response content from the data returned by the API
                            //                                                 return data?.choices[0]?.message?.content?.trim();
                            //                                             }
                            //                                         } else {
                            //                                             throw new Error('Failed to communicate with the ChatGPT API');
                            //                                         }
                            //                                     })
                            //                     );

                            //                             },
                            selector: 'textarea',  // change this value according to your HTML
                            autosave_interval: '20s',
                            max_height: 800

                        }}
                        initialValue={textToHtml(initialText)}
                        onEditorChange={(newText) => setDescription(newText)}
                        outputFormat='text'


                    />
                </div>

                <div className='mt-8 flex flex-row items-center justify-between'>
                    {/* img */}
                    <div >
                        <Typography sx={{ marginBottom: 1 }}>Choose an image</Typography>
                        {/* <ImageUpload /> */}
                        {/* image  */}
                        <div className='mb-4 flex flex-col items-center space-y-4 w-48' {...getRootProps()}>
                            <div className='flex flex-col space-y-3 items-center justify-between'>
                                {/* img button  */}
                                <div>
                                    {files?.length > 0 ? (
                                        <div className="flex border border-gray-200 px-4 rounded-lg space-x-3 py-4 items-center h-full relative">
                                            <BsFillCameraFill className="text-3xl text-gray-600" />
                                            <span className="text-gray-600 font-semibold">Change Photo</span>
                                        </div>
                                    ) : (

                                        <div className="flex border border-gray-200 px-4 rounded-lg space-x-3 py-2 items-center h-full relative">
                                            <BsFillCameraFill className="text-3xl text-gray-600" />
                                            <span className="text-gray-600 font-semibold">Upload Photo</span>
                                        </div>

                                    )}
                                </div>

                                {/* preview image  */}

                                {files?.length > 0 && (
                                    <div className=''>{image1}</div>
                                )}
                            </div>

                            <input className="input-primary" {...getInputProps()} placeholder="Upload Your Files" />

                        </div>
                    </div>

                    {/* time and date picker  */}
                    <div >
                        <Typography sx={{ marginBottom: 1 }}>Set Date & Time</Typography>
                        <Datetime
                            className='border border-gray-400 py-4 px-2 rounded-md'
                            value={date}
                            onChange={(newValue) => setDate(newValue)}

                        />
                    </div>
                    {/* category  */}
                    <div>
                        <FormControl variant="filled" sx={{ width: '100%' }}>
                            <Typography sx={{ marginBottom: 1 }}>Category</Typography>
                            <TextField id="outlined-basic" variant="outlined" placeholder="Set a category" color="success" {...register("category")} />
                        </FormControl>
                    </div>
                    {/* tag  */}
                    <div>
                        <FormControl variant="outlined"  sx={{ width: '100%' }}>
                            <Typography sx={{ marginBottom: 1 }}>Tag</Typography>
                            <Select
                                native
                                value={newTag}
                                onChange={handleChangeTag}
                                label="tag"
                            >
                                {tag_list?.map(i => (
                                    <>
                                        <option value={i?.tag}>{i?.tag}</option>
                                    </>
                                ))}
                            </Select>
                        </FormControl>
                       
                    </div>







                </div>


            </div>

            {/* buttons  */}
            <div className="flex flex-row justify-end items-center mt-12">
                <button type="submit" className='text-white bg-green-700 w-36 rounded-full text-base py-4 text-center font-semibold cursor-pointer hover:bg-green-800 transition duration-200'>Publish</button>
            </div>

        </form>
    );
}

export default AddBlogForm;