import { Box } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';

function ViewBlogPage() {
    const navigate = useNavigate();
    const [blogData, setBlogData] = React.useState({})
    const { id } = useParams();

    React.useEffect(() => {
        axios.get(`https://mw-server.vercel.app/api/blogs/${id}`)
            .then(res => {
                setBlogData(res?.data);
                
            })

    }, [id])
// const text = blogData?.description;
// console.log(text)
    // const blog = blogData?.description;
    // const strippedString = blog?.replace(/(<([^>]+)>)/gi, "");
    // let htmlObject = document.createElement('div');
    // htmlObject.innerHTML = blog;
    // htmlObject.getElementById("myDiv").style.marginTop = something;

    // const textToHtml = (text) => {
    //     const elem = document.createElement('div');
    //     return text.split(/\n\n+/).map((paragraph) => {
    //         return '<p>' + paragraph.split(/\n+/).map((line) => {
    //             elem.textContent = line;
    //             return elem.innerHTML;
    //         }).join('<br/>') + '</p>';
    //     }).join('');
    // };
    // console.log(blog)
        return (
        <Box sx={{ padding: '30px 15px' }}>
            <div>
                    <div className="flex flex-row justify-start items-center"> <p className='text-green-700 underline  rounded-full text-base py-2 text-center font-semibold cursor-pointer' onClick={() => navigate("/blogs")}><MdArrowBackIos /></p></div>
            </div>
            {/* form  */}
            <Box sx={{
                background: '#fff',
                borderRadius: '10px',
                padding: '25px',
                boxSizing: 'borderBox',
                margin: '30px 0px'
            }}>
                
                <div className='flex flex-col justify-between'>

                    {/* img  */}
                    <div className='m-auto'>
                        <img className='w-96 h-72 object-cover' src={blogData?.image} alt={blogData?.id} />
                    </div>

                    <div className='pt-6 flex flex-row justify-center items-center space-x-3'>
                        
                            <div className='flex flex-row items-center space-x-3'>
                                <span className='font-bold'>Author : </span>
                                <img src={blogData?.author?.image} className='w-12 h-12 object-cover rounded-full' alt={blogData?.author?.name} />
                                <span className='text-sm'>{blogData?.author?.name}</span>
                            </div>
                            <div className='flex flex-row items-center space-x-3'>
                                <span className='font-bold'>Published : </span>
                                <span>{blogData?.published_date} , {blogData?.published_time}</span>
                            </div>
                    </div>
                    {/* text  */}
                    <div className='py-8'>
                        <h1 className='text-center text-4xl font-bold'>{blogData?.title}</h1>
                            {/* <Typography variant="p">{parse(post.body)}</Typography> */}

                             {/* <div>
                                {blogData?.description} 
                             </div> */}
                            <div dangerouslySetInnerHTML={{ __html: blogData?.description }}></div>
                            {/* <p className='text-justify	'>{strippedString}</p> */}
                    </div>
                </div>
            </Box>
        </Box>
    );
}

export default ViewBlogPage;