import { Box } from '@mui/material';
import React from 'react';
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from 'react-router-dom';
import UpdateBlogForm from '../../components/Blogs/UpdateBlogForm';
import PageHeading from '../../components/PageHeading';

function UpdateBlogPage() {
    const {id} = useParams();
    const navigate = useNavigate();

    return (
        <Box sx={{ padding: '30px 15px' }}>

            <div className='flex flex-row items-center space-x-2'>
                <div className="flex flex-row justify-start items-center"> <p className='text-green-700 underline  rounded-full text-base py-2 text-center font-semibold cursor-pointer' onClick={() => navigate("/blogs")}><MdArrowBackIos /></p></div>
                {/* page heading  */}
                <PageHeading text="Update Blog" />
            </div>
            
            {/* form  */}
            <Box sx={{
                background: '#fff',
                borderRadius: '10px',
                padding: '25px',
                boxSizing: 'borderBox',
                margin: '30px 0px'
            }}>
                <UpdateBlogForm id={id} />
            </Box>
        </Box>
    );
}

export default UpdateBlogPage;